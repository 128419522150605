.c-about {

    &-title {

        @media (max-width: 550px) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 20px;
        }
    }

    &-top {
        padding: $gutter * 2 0;
        position: relative;
        color: $white;

        @include media-breakpoint-down(xl) {
            padding: $gutter-sm * 2 0;
        }

        @include media-breakpoint-down(md) {
            padding-top: 160px;
        }

        @media (max-width: 550px) {
            padding-top: 110px;
            padding-bottom: 40px;
        }

        &__bg {
            position: absolute;
            top: 0;
            left: $gutter * -3;
            right: $gutter * -3;
            bottom: -224px;
            z-index: -1;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            @include media-breakpoint-down(xl) {
                left: $gutter-sm * -3;
                right: $gutter-sm * -3;
            }

            @include media-breakpoint-down(sm) {
                bottom: -120px;
            }
        }
    }

    &-description {
        display: block;
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
    }

    &-img {
        margin: 0 $gutter * -1;
        margin-bottom: $gutter * 2;

        @include media-breakpoint-down(xl) {
            margin: 0 $gutter-sm * -1;
            margin-bottom: $gutter-sm * 2 + 20px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &-review {
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: $gutter * 3;
        padding-top: 90px;
        position: relative;

        @include media-breakpoint-down(xl) {
            margin-bottom: $gutter-sm * 3;
            padding-top: 60px;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            height: 36px;
            background-image: url(../img/review.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;

            @include media-breakpoint-down(xl) {
                height: 26px;
            }
        }

        &__text {
            display: block;
            margin-bottom: $gutter;
            font-size: 30px;
            line-height: 38px;

            @include media-breakpoint-down(xl) {
                margin-bottom: $gutter-sm;
                font-size: 22px;
                line-height: 30px;
            }

            @include media-breakpoint-down(lg) {
                margin-bottom: $gutter-sm;
                font-size: 16px;
                line-height: 24px;
            }

            p {
                margin-bottom: 0;

                &:not(:last-child) {
                    margin-bottom: 20px;

                    @include media-breakpoint-down(lg) {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        &__author {
            display: block;
            text-align: center;
            font-size: 30px;
            line-height: 36px;
            font-weight: 900;

            &-position {
                display: block;
                margin-top: 10px;
                font-size: 15px;
                line-height: 18px;
                font-weight: 300;
            }
        }
    }
}
.c-sidebar {
    background: $sidebar-bg;
    width: 25%;
    min-width: 320px;
    flex-shrink: 0;
    position: relative;
    z-index: 6;
    will-change: min-height;

    @include media-breakpoint-down(md) {
        position: fixed;
        top: 0;
        left: 0;
        width: 320px;
        width: calc(320px + constant(safe-area-inset-left));
        width: calc(320px + env(safe-area-inset-left));
        height: 80px;
        overflow: hidden;
        background: none;

        .is-sidebar-open & {
            height: 100%;
        }
    }

    @media (max-width: 400px) {
        width: 100%;
    }

    &-inner {
        min-height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        transform: translate(0, 0);
        transform: translate3d(0, 0, 0);
        will-change: position, transform;

        @include media-breakpoint-down(md) {
            height: 100%;
        }

        .is-collapse-open & {
            height: 100vh;
            overflow-x: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }

    &-header {
        margin-bottom: 30px;
        padding: 60px;
        padding-bottom: 0;
        display: flex;

        @include media-breakpoint-down(xl) {
            margin-top: 10px;
            padding: 30px;
            padding-bottom: 0;
        }

        @include media-breakpoint-down(md) {
            padding: 20px 30px;
            padding-left: calc(constant(safe-area-inset-left) + 30px);
            padding-left: calc(env(safe-area-inset-left) + 30px);
            margin: 0;
            flex-shrink: 0;
            background: $sidebar-bg;
            
            @supports ((backdrop-filter: blur(20px))) or (-webkit-backdrop-filter: blur(20px)) {
                background: rgba($sidebar-bg, .70);
                backdrop-filter: blur(20px);
                -webkit-backdrop-filter: blur(20px);
            }
        }
    }

    &-body {
        flex-grow: 1;
        display: flex;

        @include media-breakpoint-down(md) {
            overflow-x: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            transform: translateX(-100%);
            transition: $transition;
            background: $sidebar-bg;
            padding-left: constant(safe-area-inset-left);
            padding-left: env(safe-area-inset-left);

            @supports ((backdrop-filter: blur(20px))) or (-webkit-backdrop-filter: blur(20px)) {
                background: rgba($sidebar-bg, .70);
                backdrop-filter: blur(20px);
                -webkit-backdrop-filter: blur(20px);
            }

            .is-sidebar-animate & {
                transform: none;
            }
        }

        &__inner {
            padding: 60px;
            padding-top: 0;
            display: flex;
            flex-direction: column;
            width: 100%;

            @include media-breakpoint-down(xl) {
                padding: 30px;
                padding-top: 0;
            }
        }
    }

    &-logo {

        svg {
            vertical-align: middle;

            @include media-breakpoint-down(md) {
                width: 114px;
                height: 40px;
            }
        }
    }

    &-hamburger {
        margin-left: auto;
        margin-right: -20px;
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }

        &__link {
            display: block;
            padding: 14px 20px;
        }

        &__icon {
            width: 20px;
            height: 12px;
            display: block;
            position: relative;

            .is-sidebar-animate & {

                > div {

                    &:nth-child(1) {
                        transform: rotate(225deg);
                        top: calc(50% - 1px);
                        width: 100%;
                    }

                    &:nth-child(2) {
                        width: 0;
                        left: 50%;
                    }

                    &:nth-child(3) {
                        transform: rotate(-225deg);
                        top: calc(50% - 1px);
                        width: 100%;
                    }
                }
            }

            > div {
                position: absolute;
                width: 100%;
                left: 0;
                height: 2px;
                background: currentColor;
                transition: $transition;

                &:nth-child(1) {
                    top: 0;
                }

                &:nth-child(2) {
                    top: calc(50% - 1px);
                    width: calc(100% - 8px);
                }

                &:nth-child(3) {
                    bottom: 0;
                    width: calc(100% - 4px);
                }
            }
        }
    }

    &-types {
        width: 100%;
        margin: auto 0;

        &__list {
            list-style: none;
            padding: 0;
            margin: 0;
            margin-bottom: 50px;
            font-size: 50px;
            line-height: 58px;
            font-weight: 300;
            text-transform: uppercase;

            @media (max-width: 1700px) {
                font-size: 41px;
                line-height: 46px;
            }

            @include media-breakpoint-down(xl) {
                margin-bottom: 30px;
            }
        }

        &__item {
            padding: 10px 0;

            &-link {
                color: currentColor;
                position: relative;
                transition: none;
                white-space: nowrap;

                &.t-coworking {

                    &:before {
                        background: $coworking;
                    }
                }

                &.t-event-hall {

                    &:before {
                        background: $event;
                    }
                }

                &.t-offices {

                    &:before {
                        background: $offices;
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    margin: 0 -10px;
                    width: calc(100% + 20px);
                    height: 0;
                    transition: $transition;
                }

                .is-page-open .is-active & {

                    &:before {
                        height: 29px;

                        @media (max-width: 1700px) {
                            height: 23px;
                        }
                    }
                }

                &:hover,
                &:focus {
                    color: currentColor;
                    text-decoration: none;

                    &:before {
                        height: 29px;

                        @media (max-width: 1700px) {
                            height: 23px;
                        }
                    }
                }
            }

            &-text {
                position: relative;
            }
        }
    }

    &-nav {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;

        &__list {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 15px;
            line-height: 15px;
            font-weight: 600;
        }

        &__item {

            &-link {
                display: block;
                padding: 10px 0;
                color: currentColor;
                opacity: .5;
                will-change: opacity;

                &:hover,
                &:focus {
                    opacity: 1;
                    color: currentColor;
                    text-decoration: none;
                }

                .is-active & {
                    opacity: 1;
                }
            }
        }
    }

    &-footer {
        display: flex;
        width: 100%;
        font-size: 15px;
        line-height: 15px;
        font-weight: 700;
        flex-shrink: 0;
    }

    &-social {

        &__list {
            list-style: none;
            padding: 0;
            display: flex;
            margin: -15px;
        }

        &__item {

            &-link {
                display: block;
                padding: 15px;
                color: currentColor;

                &:hover,
                &:focus {
                    color: currentColor;
                    text-decoration: none;
                }
            }
        }
    }

    &-language {
        margin-left: auto;

        &__list {
            display: flex;
            padding: 0;
            margin: -15px;
        }

        &__item {
            list-style: none;

            &-link {
                display: block;
                padding: 15px;
                color: currentColor;
                opacity: .5;

                &:hover,
                &:focus {
                    opacity: 1;
                    color: currentColor;
                    text-decoration: none;
                }

                .is-active & {
                    opacity: 1;
                }
            }
        }
    }

    .c-ideil {
        margin: 0;
        margin-left: auto;

        &-link {

            &:hover {
                text-decoration: none;
            }
        }

        img {
            width: 35px;
            height: auto;
            vertical-align: baseline;
        }
    }
}

.scrollbar-measure{
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

.is-sidebar-open {
    overflow: hidden;
}
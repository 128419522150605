.flatpickr {

    &-wrapper {
        width: 100%;

        // &:after {
        //     content: '';
        //     position: absolute;
        //     right: 0;
        //     top: 0;
        //     width: 15px;
        //     height: 15px;
        //     background-image: url(../img/calendar.svg);
        //     background-position: center;
        //     background-size: contain;
        //     background-repeat: no-repeat;
        //     top: 15px;
        //     right: 15px;
        //     pointer-events: none;
        // }
    }

    &-input {
        width: 100%;
        background: none;
        border: none;
        box-shadow: none;
        font-size: 15px;
        line-height: 18px;
        padding: 14px 22px;
        color: $white;
        font-weight: 700;
        padding-right: 40px;
        height: 46px;

        &::placeholder {
            color: $white;
        }
    }

    &-calendar {
        border-radius: 0;
        box-shadow: none;
        width: 293px;

        &.static {
            top: calc(100% + 10px);
        }
    }

    &-current {

        &-month {
            padding: 0;
            position: static;
        }
    }

    &-months {
        align-items: center;
        padding: 0 10px;

        .flatpickr {
            
            &-month {
                padding: 20px 0;
                height: auto;
            }

            &-prev-month,
            &-next-month {
                position: static;
                height: auto;
                width: 39px;
                height: 39px;
            }
        }
    }

    &-days {
        width: 100%;
    }

    &-day {
        border-radius: 0;

        &.today {
            border-color: $gray-500;

            &:hover {
                background: $gray-500;
                border-color: $gray-500;
            }
        }

        &.selected {
            background: $body-color;
            border-color: $body-color;

            &:hover {
                background: $body-color;
                border-color: $body-color;
            }
        }
    }

    &-innerContainer {
        padding: 10px;
        padding-top: 0;
    }
}

.dayContainer {
    width: 100%;
    max-width: 100%;
    min-width: 0;
}
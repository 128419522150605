.c-carousel {
    position: relative;

    &.t-gallery {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            background: $body-color;
            width: 60px;
            height: 60px;
            pointer-events: none;
            background-image: url(../img/gallery.svg);
            background-position: center;
            background-size: 40%;
            background-repeat: no-repeat;

            @media (max-width: 550px) {
                display: none;
            }
        }
    }

    &-slide {
        width: 100%;
    }

    &-control {
        position: absolute;
        display: flex;
        align-items: center;
    }

    &-btn {
        padding: 25px;
        color: currentColor;
        border: none;
        box-shadow: none;
        background: none;
        font-size: 0;
        line-height: 0;
        cursor: pointer;

        &.t-prev {
            transform: rotate(-180deg);
        }

        &:disabled {
            opacity: .2;
            cursor: default;
        }

        svg {
            vertical-align: middle;
        }
    }

    &-counter {
        display: block;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        padding: 0 5px;

        .current {
            margin-right: 10px;
        }

        .total {
            margin-left: 10px;
        }
    }

    .flickity-viewport {
        transition: height $transition;
    }
}
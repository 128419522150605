.c-booking {
    max-width: 480px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: $gutter * 3;

    @include media-breakpoint-down(xl) {
        margin-bottom: $gutter-sm * 3;
    }

    &-text {
        display: block;
        margin-bottom: 80px;

        @include media-breakpoint-down(xl) {
            margin-bottom: 50px;
        }
    }

    &-form {
        position: relative;
    }
}
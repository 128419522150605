//# Forms
//
//*

.form {

    &-group {
        margin-bottom: 40px;
    }

    &-control {
        border: none;
        border-bottom: 2px solid $body-color;
        padding: 0;
        padding-bottom: 12px;
        font-weight: 700;
        font-size: 14px;
        background: transparent;

        &:focus {
            box-shadow: none;

            &::placeholder {
                color: rgba($body-color, .4);
            }
        }

        &::placeholder {
            transition: $transition;
        }

        .t-light & {
            border-color: $white;
            color: $white;

            &::placeholder {
                color: $white;
            }

            &:focus {

                &::placeholder {
                    color: rgba($white, .4);
                }
            }
        }
    }

    &-success {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $white;
        color: currentColor;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .is-success & {
            opacity: 1;
            visibility: visible;
        }

        &__title {
            margin-bottom: 40px;
        }

        &__text {
            display: block;
        }
    }
}
.c-error {
    text-align: center;
    padding: $gutter * 2 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(xl) {
        padding: $gutter-sm * 2 0;
    }

    @include media-breakpoint-down(md) {
        padding-top: 160px;
    }

    @media (max-width: 550px) {
        padding-top: 110px;
        padding-bottom: 40px;
    }

    &-number {
        display: block;
        font-size: 100px;
        line-height: 108px;
        font-weight: 900;
        margin-bottom: 50px;

        @media (max-width: 550px) {
            font-size: 80px;
            line-height: 88px;
            margin-bottom: 30px;
        }
    }

    &-title {
        margin-bottom: 20px;
    }

    &-text {
        display: block;
        max-width: 700px;
        margin-bottom: 50px;
    }
}
.c-contacts {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &-title {
        margin-bottom: 90px;

        @include media-breakpoint-down(xl) {
            margin-bottom: 60px;
        }

        @media (max-width: 550px) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 20px;
        }
    }

    &-list {
        margin-bottom: 40px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }
    }

    &-top {
        text-align: center;
        padding: $gutter * 2 0;

        @include media-breakpoint-down(xl) {
            padding: $gutter-sm * 2 0;
        }

        @include media-breakpoint-down(md) {
            padding-top: 160px;
        }

        @media (max-width: 550px) {
            padding-top: 110px;
            padding-bottom: 40px;
        }
    }

    &-item {

        &__text {
            display: block;
        }

        &:not(:last-child) {
            margin-bottom: 28px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 18px;
            }
        }
    }

    &-social {
        text-align: center;
        font-weight: 700;
        font-size: 15px;
        line-height: 15px;

        &__list {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            justify-content: center;
        }

        &__link {
            padding: 20px;
            display: block;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    &-map {
        background: #eaeaea;
        min-height: 400px;
        margin: 0 $gutter * -3;
        position: relative;
        flex-grow: 1;

        @include media-breakpoint-down(xl) {
            margin: 0 $gutter-sm * -3;
        }

        @include media-breakpoint-down(sm) {
            min-height: 250px;
        }
    }

    &-wrap {
        display: flex;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }
    }

    &-content,
    &-feedback {
        flex-shrink: 0;
        width: 100%;
        min-height: 210px;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-feedback {
        background: $body-bg;
        transform: translateX(-30px);
        padding: 0 30px;
        transition: $transition;

        @include media-breakpoint-down(sm) {
            transform: none;
            padding: 0;
            margin-top: 40px;
        }

        &.is-open {
            transform: translateX(-100%);
        }

        &__toggle {
            will-change: transform;
            position: absolute;
            left: 0;
            top: 50%;
            transform-origin: left top;
            transform: rotate(-90deg) translateX(-50%);
            z-index: 1;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            .open {
                display: block;

                .is-open & {
                    display: none;
                }
            }

            .hide {
                display: none;

                .is-open & {
                    display: block;
                }
            }
        }

        &__form {
            max-width: 480px;
            margin: 0 auto;

            @include media-breakpoint-down(lg) {
                max-width: 280px;
            }
        }
    }
}
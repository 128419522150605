.c-numbers {
    margin-bottom: $gutter * 3;

    @include media-breakpoint-down(xl) {
        margin-bottom: $gutter-sm * 3;
    }

    &-list {
        list-style: none;
        padding: 0;
        padding-left: constant(safe-area-inset-right);
        padding-left: env(safe-area-inset-right);
        padding-right: constant(safe-area-inset-right);
        padding-right: env(safe-area-inset-right);
        margin: 0 $gutter * -2;
        margin-bottom: -40px;
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-down(xl) {
            margin: 0 $gutter-sm * -2;
            margin-bottom: -40px;
        }

        @include media-breakpoint-down(sm) {
            justify-content: center;
        }

        @media (max-width: 550px) {
            margin: 0 -30px;
            margin-bottom: -40px;
        }

        &__item {
            width: 33.333%;
            padding: 0 $gutter / 2;
            margin-bottom: 40px;

            @include media-breakpoint-down(xl) {
                padding: 0 $gutter-sm / 2;
            }

            @include media-breakpoint-down(sm) {
                width: 50%;
                max-width: 260px;
            }

            &:nth-child(3n+2) {

                @include media-breakpoint-up(md) {
                    margin-top: $gutter-sm;
                }

                @include media-breakpoint-up(xxl) {
                    margin-top: $gutter;
                }
            }

            &:nth-child(3n+3) {

                @include media-breakpoint-up(md) {
                    margin-top: -$gutter-sm;
                }

                @include media-breakpoint-up(xxl) {
                    margin-top: -$gutter;
                }
            }

            &:nth-child(3n+1):nth-child(even) {

                @include media-breakpoint-up(md) {
                    margin-left: $gutter-sm * -1;
                }

                @include media-breakpoint-up(xxl) {
                    margin-left: $gutter * -1;
                }
            }

            &:nth-child(4n+1) {

                @include media-breakpoint-down(sm) {
                    margin-left: -30px;
                }

                @media (max-width: 550px) {
                    margin-left: 0;
                }
            }

            &:nth-child(2n+2) {

                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                }
            }
        }
    }

    &-item {
        font-weight: 700;
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            width: 100%;
            padding-bottom: 100%;
            display: block;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &:hover {

            @include media-breakpoint-up(lg) {
                .c-numbers-item {

                    &__bg {
                        opacity: 1;
                        visibility: visible;
                    }

                    &__count {
                        color: $white;
                    }
                }
            }
        }

        &__bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
        }

        &__overlay {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            padding: $gutter;
            z-index: 1;

            @include media-breakpoint-down(xl) {
                padding: $gutter-sm;
            }

            @include media-breakpoint-only(lg) {
                padding: 20px;
            }

            @media (max-width: 850px) {
                padding: 20px;
            }

            @include media-breakpoint-down(sm) {
                position: static;
            }
        }

        &__count {
            display: block;
            position: relative;
            font-size: 144px;
            line-height: 144px;
            margin-bottom: 30px;
            transition: $transition;

            @include media-breakpoint-down(xl) {
                font-size: 120px;
                line-height: 120px;
                margin-bottom: 15px;
            }

            @include media-breakpoint-only(lg) {
                font-size: 80px;
                line-height: 80px;
                margin-bottom: 10px;
            }

            @media (max-width: 850px) {
                font-size: 80px;
                line-height: 80px;
                margin-bottom: 10px;
            }

            .t-coworking & {
                color: $coworking;
            }

            .t-event-hall & {
                color: $event;
            }

            .t-offices & {
                color: $offices;
            }

            &:after {
                content: attr(data-count);
                position: absolute;
                left: 10px;
                top: 10px;
                display: block;
                color: rgba($body-color, .1);
                z-index: -1;

                @include media-breakpoint-down(xl) {
                    left: 8px;
                    top: 8px;
                }

                @include media-breakpoint-only(lg) {
                    left: 5px;
                    top: 5px;
                }

                @media (max-width: 850px) {
                    left: 5px;
                    top: 5px;
                }
            }
        }

        &__text {
            display: block;
            margin-left: auto;
            font-size: 20px;
            line-height: 28px;
            width: 100%;
            max-width: 150px;
            font-style: italic;

            @include media-breakpoint-down(xl) {
                font-size: 18px;
                line-height: 26px;
            }

            @include media-breakpoint-only(lg) {
                font-size: 16px;
                line-height: 18px;
            }

            @media (max-width: 850px) {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
}
// Application variables
//
//


// Color
//
//

$white: #fff;
$black: #000;

$gray-100:      #f5f5f5;
$gray-200:      #eee;
$gray-300:      #d8d8d8;
$gray-500:      #aaa;
$gray-600:      #888;
$gray-700:      #555;
$gray-800:      #333;
$gray-900:      #222;

$body-color:    $gray-900;
$body-bg:       $white;

$sidebar-bg:    $gray-100;

$blue:          #35A9E1;
$indigo:        #6610f2;
$purple:        #6f42c1;
$pink:          #e83e8c;
$red:           #DC0000;
$orange:        #fd7e14;
$yellow:        #F2B944;
$green:         #94C020;
$teal:          #20c997;
$cyan:          #17a2b8;

$primary:       $gray-900;
$success:       $green;
$info:          $blue;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

$coworking:     $green;
$event:         $yellow;
$offices:       $blue;

$linkedin:      #0077B5;
$instagram:     #e4405f;
$facebook:      #4267B2;



// Links
//
//

$link-color:                $primary;
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              true;
$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:       true;
$enable-print-styles:       true;


// Grid
//
//

$grid-columns:      12;
$grid-gutter-width: 60px;

$gutter:            $grid-gutter-width;
$gutter-xs:         round($gutter * 0.33);
$gutter-xs-2:       round($gutter * 0.66);
$gutter-sm:         round($gutter * 0.5);
$gutter-md:         round($gutter * 1.5);
$gutter-lg:         round($gutter * 2);

$spacer:            1rem;

$spacer-xs:         ($spacer * 0.33);
$spacer-xs-2:       ($spacer * 0.66);
$spacer-sm:         ($spacer * 0.5);
$spacer-md:         ($spacer * 1.5);
$spacer-lg:         ($spacer * 2);

$body-max-width:    1980px;

$breakpoints-xs:   0;
$breakpoints-sm:   576px;
$breakpoints-md:   768px;
$breakpoints-lg:   992px;
$breakpoints-xl:   1260px;
$breakpoints-xxl:  1500px;

$grid-breakpoints: (
  xs: $breakpoints-xs,
  sm: $breakpoints-sm,
  md: $breakpoints-md,
  lg: $breakpoints-lg,
  xl: $breakpoints-xl,
  xxl: $breakpoints-xxl
);

$container-sm:   540px;
$container-md:   720px;
$container-lg:   960px;
$container-xl:   1200px;

$container-max-widths: (
  sm: $container-sm,
  md: $container-md,
  lg: $container-lg,
  xl: $container-xl
);


// Components
//
// Define common padding and border radius sizes and more.

$cubic-bezier:       cubic-bezier(0.25, 0.1, 0.25, 1);
$transition:         .5s $cubic-bezier;
$transition-base:    $transition;


// Fonts
//
//

$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            "Lato", $font-family-sans-serif;

$font-size-base:              2rem;
$line-height-base:            1.4;
$font-weight-base:            300;


// Btn
//
//

$btn-font-weight:             700;
$btn-focus-width:             0;
$btn-box-shadow:              none;
$btn-focus-box-shadow:        none;
$btn-active-box-shadow:       none;

$btn-disabled-opacity:        .65;


$input-color:                           $body-color;
$input-border-color:                    $body-color;
$input-focus-border-color:              $body-color;
$input-focus-box-shadow:                none;
$input-bg:                              transparent;
$input-focus-bg:                        transparent;

$input-height-border:                   2px;
$input-line-height:                     1;

$input-placeholder-color:               $body-color;


$modal-content-bg:                  transparent;
$modal-backdrop-bg:                 transparent;
$modal-backdrop-opacity:            1;
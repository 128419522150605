.skrollable {
    transition: $transition;
    transition-duration: 1s;

    &-before {
        opacity: 0;
        visibility: hidden;
        transform: translateY(50px);
    }

    &-after {
        opacity: 1;
        visibility: visible;
        transform: none;
    }
}
.c-collapse {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    z-index: 5;

    .is-collapse-open & {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    &-dialog {
        padding-left: 25%;
        min-height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        background: $body-color;
        color: $white;
        transform: translateX(-100%);
        transition: $transition;

        @include media-breakpoint-down(lg) {
            padding-left: 320px;
        }

        @include media-breakpoint-down(md) {
            padding-left: 0;
            transition: .5s $cubic-bezier;
        }

        .show & {
            transform: none;
        }
    }

    &-content {
        padding: $gutter * 2 0;
        width: 100%;

        @include media-breakpoint-down(xl) {
            padding: $gutter-sm * 2 0;
        }

        @include media-breakpoint-down(md) {
            padding-top: 160px;
        }

        @media (max-width: 550px) {
            padding-top: 110px;
            padding-bottom: 40px;
        }
    }

    &-body {
        position: relative;
    }

    &-close {
        will-change: transform;
        position: absolute;
        left: 100%;
        top: 50%;
        transform-origin: left top;
        transform: rotate(-90deg) translateX(-50%) translateY(-100%);

        @include media-breakpoint-only(lg) {
            transform: rotate(-90deg) translateX(-50%) translateY(20px);
        }

        @include media-breakpoint-down(sm) {
            transform: rotate(-90deg) translateX(-50%) translateY(20px);
        }

        @media (max-width: 550px) {
            z-index: 1;
            position: fixed;
            top: 105px;
            right: 20px;
            left: inherit;
            transform: none;
            width: 40px;
            height: 40px;

            &:after,
            &:before {
                content: '';
                width: 70%;
                height: 2px;
                background: $white;
                left: 15%;
                display: block;
                opacity: 1;
            }

            &:before {
                top: calc(50% - 1px);
                transform: rotate(45deg);
            }

            &:after {
                bottom: calc(50% - 1px);
                transform: rotate(-45deg);
                animation: none;
            }

            &:hover {

                &:after {
                    animation: none;
                }
            }

            .text {
                display: none;
            }
        }
    }

    .btn-link {
        color: $white;
    }

    .form-success {
        background: $body-color;
    }
}

.is-collapse-open {
    overflow: hidden;
}
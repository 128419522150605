.c-friends {
    margin-bottom: $gutter * 3;

    @include media-breakpoint-down(xl) {
        margin-bottom: $gutter-sm * 3;
    }

    &-title {
        display: block;
        text-align: center;
    }

    &-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style: none;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            border: 1px solid $white;
            pointer-events: none;
            z-index: 1;
        }

        &__item {
            width: 33.3333%;
            border-right: 1px solid $gray-300;
            border-bottom: 1px solid $gray-300;
            position: relative;

            @include media-breakpoint-down(sm) {
                width: 50%;
            }

            &:after {
                content: '';
                display: block;
                width: 100%;
                padding-bottom: 100%;
            }
        }
    }

    &-item {
        padding: $gutter;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(xl) {
            padding: $gutter-sm;
        }

        @include media-breakpoint-down(sm) {
            padding: 15px;
        }

        &__img {

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}
.c-reserve {

    &-wrap {
        display: flex;
        margin: 0 $gutter * -1;
        align-items: center;

        @include media-breakpoint-down(xl) {
            margin: 0 $gutter-sm * -1;
        }
    }

    &-content,
    &-img {
        width: 50%;
        flex-shrink: 0;
        padding: 0 $gutter;

        @include media-breakpoint-down(xl) {
            padding: 0 $gutter-sm;
        }

        @include media-breakpoint-only(lg) {
            width: 80%;
        }

        @include media-breakpoint-down(sm) {
            width: 80%;
        }

        @media (max-width: 550px) {
            width: 100%;
        }
    }

    &-img {
        width: calc(50% + #{$gutter * 3});
        margin-left: auto;

        @include media-breakpoint-down(xl) {
            width: calc(50% + #{$gutter-sm * 3});
        }

        @include media-breakpoint-only(lg) {
            display: none;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &-content {
        position: relative;
        max-width: 600px;

        @include media-breakpoint-down(xl) {
            max-width: 540px;
        }
    }

    &-item {

        &:not(:last-child) {
            margin-bottom: 80px;

            @include media-breakpoint-down(xl) {
                margin-bottom: 50px;
            }
        }

        &__title {
            font-weight: 700;
            line-height: 28px;
            font-size: 20px;
            display: block;
            margin-bottom: 40px;

            @media (max-width: 550px) {
                text-align: center;
            }
        }
    }

    &-types {
        margin: -10px 0;

        @media (max-width: 550px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &-date {
        display: flex;
        position: relative;

        @media (max-width: 550px) {
            flex-wrap: wrap;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            border: 2px solid $white;
            pointer-events: none;
        }

        &:after {
            content: '';
            position: absolute;
            left: calc(50% - 1px);
            width: 2px;
            height: 100%;
            background: rgba($white, .2);

            @media (max-width: 550px) {
                width: 100%;
                top: calc(50% - 1px);
                left: 0;
                height: 2px;
            }
        }

        &__item {
            width: 50%;
            position: relative;

            @media (max-width: 550px) {
                width: 100%;
            }

            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 15px;
                height: 15px;
                background-image: url(../img/calendar.svg);
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                top: 15px;
                right: 15px;
                pointer-events: none;
            }
        }
    }

    .custom {

        &-control {
            padding: 0;

            &.t-coworking {

                .custom-control-input {

                    &:checked {

                        ~ .custom-control-label {
                            color: $coworking;
                        }
                    }
                }

                .custom-control-label {

                    &:hover {
                        color: $coworking;
                    }
                }
            }

            &.t-event-hall {

                .custom-control-input {

                    &:checked {

                        ~ .custom-control-label {
                            color: $event;
                        }
                    }
                }

                .custom-control-label {

                    &:hover {
                        color: $event;
                    }
                }
            }

            &.t-offices {

                .custom-control-input {

                    &:checked {

                        ~ .custom-control-label {
                            color: $offices;
                        }
                    }
                }

                .custom-control-label {

                    &:hover {
                        color: $offices;
                    }
                }
            }

            &-input {

                &:checked {

                    ~ .custom-control-label {

                        &:after {
                            background-image: url(../img/check.svg);
                            opacity: 1;
                        }
                    }
                }
            }

            &-label {
                font-size: 40px;
                line-height: 40px;
                font-weight: 300;
                padding: 10px 0;
                color: rgba($white, .5);
                transition: $transition;
                cursor: pointer;

                @media (max-width: 550px) {
                    font-size: 30px;
                    line-height: 30px;
                }

                &:before {
                    content: none;
                }

                &:after {
                    width: 32px;
                    height: 32px;
                    background-image: url(../img/check.svg);
                    background-position: center;
                    background-size: contain;
                    left: -42px;
                    top: 14px;
                    opacity: 0;
                    transition: $transition;
                }
            }
        }
    }

    .form-success {
        padding: 0 $gutter;

        @include media-breakpoint-down(xl) {
            padding: 0 $gutter-sm;
        }
    }
}
.c-pricing {
    margin-bottom: $gutter * 3;
    font-weight: 400;

    @include media-breakpoint-down(xl) {
        margin-bottom: $gutter-sm * 3;
    }

    &-wrap {
        max-width: 800px;
        margin: 0 auto;
    }

    &-title {
        text-align: center;
    }

    &-item {
        margin-bottom: $gutter * 2;

        @include media-breakpoint-down(xl) {
            margin-bottom: $gutter-sm * 2;
        }

        &__title {
            margin-bottom: 40px;
            padding-bottom: 20px;
            border-bottom: 4px solid currentColor;

            @include media-breakpoint-down(lg) {
                margin-bottom: 20px;
                border-width: 3px;
            }
        }

        &__wrap {
            display: flex;

            @include media-breakpoint-down(lg) {
                flex-wrap: wrap;
            }
        }
    }

    &-info {
        flex-shrink: 0;
        width: 60%;
        font-size: 14px;
        line-height: 20px;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                position: relative;
                padding-left: 15px;

                &:before {
                    content: '—';
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                &:not(:last-child) {
                    margin-bottom: 14px;
                }
            }
        }
    }

    &-rate {
        width: 40%;
        flex-shrink: 0;

        @include media-breakpoint-down(lg) {
            width: 100%;
            margin-bottom: 30px;
        }

        &__wrap {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -20px;
            margin-bottom: -20px;
        }

        &__item {
            padding: 0 20px;
            max-width: 155px;
            margin-bottom: 20px;

            @include media-breakpoint-down(lg) {
                max-width: 200px;
            }
        }

        &__price {
            font-size: 30px;
            line-height: 36px;
            font-weight: 900;
            display: block;
            padding-bottom: 12px;
            position: relative;
            margin-bottom: 10px;

            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 40px;
                height: 2px;

                .t-coworking & {
                    background: $coworking;
                }

                .t-event-hall & {
                    background: $event;
                }

                .t-offices & {
                    background: $offices;
                }
            }

            .currency {
                font-size: 20px;
                font-weight: 400;
                display: inline-block;
                vertical-align: middle;
            }
        }

        &__period {
            display: block;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 1px;
            margin-right: -1px;
            text-transform: uppercase;
            font-weight: 900;
            margin-bottom: 10px;
        }

        &__info {
            display: block;
            font-size: 14px;
            line-height: 20px;
        }
    }

    &-other {
        position: relative;

        &__wrap {
            display: flex;
            flex-wrap: wrap;
            max-width: 800px;
            margin: 0 auto;
        }

        &__content {
            width: 60%;
            flex-shrink: 0;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        &__img {
            width: calc(40% + #{$gutter * 3});
            position: absolute;
            height: 100%;
            right: $gutter * -3;
            display: flex;
            align-items: center;
            padding-left: $gutter * 2;

            @include media-breakpoint-down(xl) {
                width: calc(40% + #{$gutter-sm * 3});
                right: $gutter-sm * -3;
                padding-left: $gutter-sm * 2;
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }

            &-wrap {
                position: relative;
                width: 100%;

                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    padding-bottom: 148.2%;
                }

                svg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    max-width: 100%;
                    height: auto;
                    opacity: .1;
                }
            }
        }

        &__item {

            &:not(:last-child) {
                margin-bottom: $gutter * 2;

                @include media-breakpoint-down(xl) {
                    margin-bottom: $gutter-sm * 2;
                }
            }
        }

        &__title {

            @media (max-width: 550px) {
                font-size: 26px;
                line-height: 32px;
                margin-bottom: 20px;
            }

            &.t-note {
                position: relative;

                &:before {
                    content: '*';
                    color: $danger;
                    position: absolute;
                    left: -23px;
                    top: 0;

                    @media (max-width: 550px) {
                        left: -18px;
                    }
                }
            }
        }

        &__text {

            ul {
                font-size: 14px;
                line-height: 17px;
                list-style: none;
                padding: 0;
                margin: 0;

                li {

                    &:not(:last-child) {
                        margin-bottom: 14px;
                    }
                }
            }
        }
    }
}
//# Utilities
//
//*


//## Reset
//
//*

//### Margins

.u-m-0 {
    margin: 0 !important;
}

.u-mt-0 {
    margin-top: 0 !important;
}

.u-mb-0 {
    margin-bottom: 0 !important;
}

//### Paddings

.u-p-0 {
    padding: 0 !important;
}

.u-pl-0 {
    padding-left: 0 !important;
}

.u-pr-0 {
    padding-right: 0 !important;
}


//## Text
//
//*

.u-nobr { // instead </nobr>
    white-space: nowrap;
}

.u-ellipsis {
    // @include text-truncate;
}

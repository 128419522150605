.c-events {
    margin-bottom: $gutter * 3;

    @include media-breakpoint-down(xl) {
        margin-bottom: $gutter-sm * 3 + 40px;
    }

    &-title {
        display: block;
        text-align: center;
    }

    &-item {
        width: 100%;
        background: $gray-300;
        position: relative;
        font-weight: 900;

        @include media-breakpoint-down(sm) {
            height: 320px;
        }

        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 66.66667%;
        }

        &__wrap {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            padding: 40px;
            padding-top: 50px;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(xl) {
                padding: 30px;
            }

            @at-root a#{&} {
                text-decoration: none;
            }
        }

        &__top {
            margin-bottom: 60px;

            @include media-breakpoint-down(xl) {
                margin-bottom: 30px;
            }
        }

        &__date {
            display: block;
            margin-bottom: 20px;

            &-day {
                display: inline-block;
                vertical-align: middle;
                font-size: 80px;
                line-height: 80px;

                @include media-breakpoint-down(xl) {
                    font-size: 60px;
                    line-height: 60px;
                }
            }

            &-month {
                font-size: 40px;
                line-height: 48px;
                display: inline-block;
                vertical-align: top;
                margin-top: 2px;

                @include media-breakpoint-down(xl) {
                    font-size: 28px;
                    line-height: 36px;
                }

                &:before {
                    content: '/';
                }
            }
        }

        &__time {
            font-size: 12px;
            line-height: 15px;
            display: block;
        }

        &__title {
            display: block;
            margin-bottom: 30px;

            max-height: 82px;
            display: -webkit-inline-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @include media-breakpoint-down(lg) {
                max-height: 72px;
            }
        }

        &__type {
            margin-top: auto;
            display: block;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 1px;
            text-transform: uppercase;
            position: relative;

            &:before {
                content: '';
                width: 40px;
                height: 2px;
                display: block;
                background: $body-color;
                position: absolute;
                top: -12px;
                left: 0;
            }
        }
    }

    .c-carousel {
        margin: 0 $gutter * -3;

        @include media-breakpoint-down(xl) {
            margin: 0 $gutter-sm * -3;
        }

        @media (max-width: 550px) {
            margin: 0 -30px;
        }

        &-slider {
            display: flex;

            .flickity-viewport {
                width: 100%;
            }

            .flickity-slider {
                margin-left: $gutter * 3;

                @include media-breakpoint-down(xl) {
                    margin-left: $gutter-sm * 3;
                }

                @media (max-width: 550px) {
                    margin-left: 0;
                }
            }
        }

        &-slide {
            width: 660px;
            margin-right: $gutter;
            flex-shrink: 0;

            @include media-breakpoint-down(xl) {
                width: 500px;
                margin-right: $gutter-sm;
            }

            @include media-breakpoint-down(sm) {
                width: 70%;
                min-width: 320px;
            }

            @media (max-width: 550px) {
                margin-right: 15px;
            }
        }

        &-control {
            left: $gutter * 3 + 660px + $gutter - 25px;
            top: calc(100% + 25px);

            @include media-breakpoint-down(xl) {
                left: $gutter-sm * 3 + 500px + $gutter-sm - 25px;
                top: calc(100% + 10px);
            }

            @include media-breakpoint-only(lg) {
                left: 50%;
                transform: translateX(-50%);
            }

            @media (max-width: 850px) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
//# Scaffolding
//
//* Reset

html {
    font-size: 10px;
}

body {
    fill: $body-color;
    -webkit-font-smoothing: antialiased;

    @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: 24px;
    }
}

* {
    outline: none !important;
}


//## Transitions
//
//*

button,
.btn,
a {
    transition: $transition;
}


//## Text
//
//*

.text-coworking,
.text-event,
.text-offices {

    @at-root a#{&} {
        position: relative;
        white-space: nowrap;

        &:before {
            content: '';
            width: calc(100% + 4px);
            height: 0;
            position: absolute;
            left: -2px;
            bottom: 0;
            z-index: -1;
            transition: $transition;
        }

        &:hover {
            color: $body-color;
            text-decoration: none;

            &:before {
                height: 30%;
            }
        }
    }
}

.text-coworking {
    color: $coworking;

    @at-root a#{&} {

        &:before {
            background: $coworking;
        }
    }
}

.text-event {
    color: $event;

    @at-root a#{&} {

        &:before {
            background: $event;
        }
    }
}

.text-offices {
    color: $offices;

    @at-root a#{&} {

        &:before {
            background: $offices;
        }
    }
}

.title {
    display: block;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 15px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 80px;
    margin-right: -15px;

    @include media-breakpoint-down(xl) {
        margin-bottom: 50px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 8px;
        margin-right: -8px;
        font-weight: 900;
    }
}

svg {

    .coworking {
        transition: $transition;

        .is-page-open.t-coworking & {
            fill: $coworking;
        }
    }

    .event-hall {
        transition: $transition;

        .is-page-open.t-event-hall & {
            fill: $event;
        }
    }

    .offices {
        transition: $transition;

        .is-page-open.t-offices & {
            fill: $offices;
        }
    }

    .lines {

        &-item {
            transition: $transition;
        }
    }

    .types {

        &-item {
            opacity: 0;
            transition: $transition;
        }

        .coworking {
            fill: $coworking;

            .is-page-open.t-coworking & {
                opacity: 1;
            }
        }

        .event-hall {
            fill: $event;

            .is-page-open.t-event-hall & {
                opacity: 1;
            }
        }

        .offices {
            fill: $offices;

            .is-page-open.t-offices & {
                opacity: 1;
            }
        }
    }
}



a {

    &.t-facebook {

        &:hover {
            color: $facebook;
        }
    }

    &.t-instagram {

        &:hover {
            color: $instagram;
        }
    }
}

::selection {
    background-color: rgba($body-color, .2);
}

.c-collapse {

    ::selection {
        background-color: rgba($white, .2);
    }
}

//** text selection
body.t-coworking {

    ::selection {
        background-color: rgba($coworking, .6);
    }
}

body.t-event-hall {

    ::selection {
        background-color: rgba($event, .6);
    }
}

body.t-offices {

    ::selection {
        background-color: rgba($offices, .6);
    }
}

.c-project {

    &-top {
        padding-top: $gutter * 2;
        padding-bottom: $gutter * 2;
        position: relative;

        @include media-breakpoint-down(xl) {
            padding-top: $gutter-sm * 2;
            padding-bottom: $gutter-sm * 2;
        }

        @include media-breakpoint-down(md) {
            padding-top: 160px;
        }

        @media (max-width: 550px) {
            padding-top: 110px;
            padding-bottom: 40px;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: $gutter * -3;
            right: $gutter * -3;
            z-index: -1;

            @include media-breakpoint-down(xl) {
                left: $gutter-sm * -3;
                right: $gutter-sm * -3;
            }

            .t-coworking & {
                background: rgba($coworking, .1);
            }

            .t-event-hall & {
                background: rgba($event, .1);
            }

            .t-offices & {
                background: rgba($offices, .1);
            }
        }
    }

    &-title {

        @media (max-width: 550px) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 20px;
        }
    }

    &-description {
        display: block;
        max-width: 800px;
        margin: 0 auto;

        p {
            margin-bottom: 0;

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }

    &-gallery {
        margin: 0 $gutter * -1;
        position: relative;
        margin-bottom: $gutter * 2 + 40px;
        position: relative;

        @include media-breakpoint-down(xl) {
            margin: 0 $gutter-sm * -1;
            margin-bottom: $gutter-sm * 2 + 29px;
        }

        @media (max-width: 550px) {
            margin: 0 -30px;
            margin-bottom: 30px + 58px;
        }

        &:after {
            content: '';
            width: 100%;
            display: block;
            padding-bottom: 50%;
        }

        &:before {
            content: '';
            height: 50%;
            position: absolute;
            left: $gutter * -2;
            right: $gutter * -2;
            top: 0;
            display: block;

            @include media-breakpoint-down(xl) {
                left: $gutter-sm * -2;
                right: $gutter-sm * -2;
            }

            .t-coworking & {
                background: rgba($coworking, .1);
            }

            .t-event-hall & {
                background: rgba($event, .1);
            }

            .t-offices & {
                background: rgba($offices, .1);
            }
        }

        .c-carousel {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            &-slider {
                width: 100%;
                flex-grow: 1;

                &.flickity-enabled {

                    .c-carousel-slide {
                        display: block;
                    }
                }
            }

            &-slide {
                width: 100%;
                margin: 0;

                &:not(:first-child) {
                    display: none;
                }
            }

            &-control {
                position: relative;
                z-index: 1;
                margin-top: -40px;
                background: $body-color;
                flex-shrink: 0;
                color: $white;
                padding: 11px 34px;

                @include media-breakpoint-down(xl) {
                    padding: 0 10px;
                    margin-top: -29px;
                }

                @media (max-width: 550px) {
                    margin-top: 0;
                    background: transparent;
                    color: $body-color;
                }
            }
        }

        &__link {
            display: block;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &-text {
        display: block;
        margin: 0 auto;
        margin-bottom: $gutter * 3;
        max-width: 800px;

        @include media-breakpoint-down(xl) {
            margin-bottom: $gutter-sm * 4;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 40px;
        }

        p {
            margin-bottom: 0;

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }

    &-about {
        margin-bottom: $gutter * 3;

        @include media-breakpoint-down(xl) {
            margin-bottom:  $gutter-sm * 3 + 20px;
        }

        &__list {
            list-style: none;
            padding: 0;
            margin: 0;

            &-item {

                &:not(:last-child) {
                    margin-bottom: 80px;
                }

                &:nth-child(odd) {

                    .c-project-about {

                        &__img {
                            margin-left: $gutter * -3;

                            @include media-breakpoint-down(xl) {
                                margin-left: $gutter-sm * -3;
                            }

                            @media (max-width: 550px) {
                                margin-left: -30px;
                            }

                            &:before {
                                left: 0;
                            }

                            &-item {

                                &:nth-child(2) {
                                    margin-left: $gutter * 3;

                                    @include media-breakpoint-down(xl) {
                                        margin-left: $gutter-sm * 3;
                                    }

                                    @media (max-width: 550px) {
                                        margin-left: -30px;
                                    }
                                }
                            }
                        }

                        &__info {
                            padding-top: $gutter;
                            padding-left: $gutter;

                            @include media-breakpoint-down(xl) {
                                padding-top: $gutter-sm;
                                padding-left: $gutter-sm;
                            }

                            @include media-breakpoint-only(lg) {
                                margin-left: auto;
                                padding-top: 0;
                            }

                            @include media-breakpoint-down(sm) {
                                margin-left: auto;
                                padding-top: 0;
                            }
                        }
                    }
                }

                &:nth-child(even) {

                    .c-project-about {

                        &__item {

                            &-wrap {
                                align-items: center;
                            }

                            &-col {

                                &:first-child {
                                    order: 1;

                                    @include media-breakpoint-only(lg) {
                                        order: 0;
                                    }

                                    @include media-breakpoint-down(sm) {
                                        order: 0;
                                    }
                                }
                            }
                        }

                        &__img {
                            margin-right: $gutter * -3;

                            @include media-breakpoint-down(xl) {
                                margin-right: $gutter-sm * -3;
                            }

                            @include media-breakpoint-only(lg) {
                                margin-left: auto;
                            }

                            @include media-breakpoint-down(sm) {
                                margin-left: auto;
                            }

                            @media (max-width: 550px) {
                                margin-right: -30px;
                            }

                            &:before {
                                right: 0;
                            }

                            &-item {

                                &:nth-child(2) {
                                    margin-left: $gutter;

                                    @include media-breakpoint-down(xl) {
                                        margin-left: $gutter-sm;
                                    }

                                    @media (max-width: 550px) {
                                        margin-left: 15px;
                                    }
                                }
                            }
                        }

                        &__info {
                            padding-right: $gutter;

                            @include media-breakpoint-down(xl) {
                                padding-right: $gutter-sm;
                            }

                            @include media-breakpoint-only(lg) {
                                margin-right: auto;
                            }

                            @include media-breakpoint-down(sm) {
                                margin-right: auto;
                            }

                            &-title {

                                @include media-breakpoint-only(lg) {
                                    text-align: right;
                                }

                                @include media-breakpoint-down(sm) {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__item {

            &-wrap {
                display: flex;
                flex-wrap: wrap;
                margin: 0 $gutter * -1;

                @include media-breakpoint-down(xl) {
                    margin: 0 $gutter-sm * -1;
                }
            }

            &-col {
                width: 50%;
                padding: 0 $gutter;
                z-index: 1;

                @include media-breakpoint-down(xl) {
                    padding: 0 $gutter-sm;
                }

                @include media-breakpoint-only(lg) {
                    width: 100%;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }

        &__img {
            width: calc(100% + #{$gutter  * 3});
            padding-top: $gutter * 2;
            position: relative;

            @include media-breakpoint-down(xl) {
                width: calc(100% + #{$gutter-sm  * 3});
                padding-top: $gutter-sm * 2;
            }

            @include media-breakpoint-only(lg) {
                max-width: 80%;
            }

            @include media-breakpoint-down(sm) {
                max-width: 80%;
            }

            @media (max-width: 550px) {
                width: 100%;
                max-width: none;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                width: calc(100% - #{$gutter * 3});
                height: calc(100% - #{$gutter});

                @include media-breakpoint-down(xl) {
                    width: calc(100% - #{$gutter-sm * 3});
                    height: calc(100% - #{$gutter-sm});
                }

                .t-coworking & {
                    background: rgba($coworking, .1);
                }

                .t-event-hall & {
                    background: rgba($event, .1);
                }

                .t-offices & {
                    background: rgba($offices, .1);
                }
            }

            &-item {
                position: relative;

                &:before {
                    content: '';
                    width: 100%;
                    display: block;
                    padding-bottom: 66.66667%;
                }

                &:nth-child(2) {
                    width: calc(100% - #{$gutter * 4});
                    margin-top: $gutter * -2;

                    @include media-breakpoint-down(xl) {
                        width: calc(100% - #{$gutter-sm * 4});
                        margin-top: $gutter-sm * -2;
                    }

                    @include media-breakpoint-only(lg) {
                        display: none;
                    }

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__info {

            @include media-breakpoint-only(lg) {
                margin-top: -29px;
            }

            @include media-breakpoint-down(sm) {
                margin-top: -29px;
            }

            &-title {
                margin-left: $gutter * -1;

                @include media-breakpoint-down(xl) {
                    margin-left: $gutter-sm * -1;
                }

                @media (max-width: 550px) {
                    margin-bottom: 20px;
                }
            }

            &-text {
                display: block;
                margin-bottom: 60px;

                @include media-breakpoint-down(xl) {
                    margin-bottom: 30px;
                }

                @media (max-width: 550px) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}
//# Typography
//
//*

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
    display: block;
    font-weight: 700;
    margin-bottom: 30px;

    @media (max-width: 550px) {
        margin-bottom: 20px;
    }
}

h1, .h1 {
    font-size: 100px;
    line-height: 108px;
    margin-bottom: 50px;

    @include media-breakpoint-down(xl) {
        font-size: 80px;
        line-height: 88px;
        margin-bottom: 40px;
    }

    @include media-breakpoint-down(lg) {
        font-size: 60px;
        line-height: 68px;
    }

    @media (max-width: 550px) {
        margin-bottom: 20px;
    }
}

h2, .h2 {
    font-size: 60px;
    line-height: 68px;
    margin-bottom: 54px;

    @include media-breakpoint-down(xl) {
        margin-bottom: 40px;
    }

    @include media-breakpoint-down(lg) {
        font-size: 40px;
        line-height: 48px;
    }

    @media (max-width: 550px) {
        margin-bottom: 20px;
    }
}

h3, .h3 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 900;
}

b, strong {
    font-weight: bold;
}


//## Forms
//
//*

textarea {
    resize: vertical;
}


//## Misc
//
//*

abbr[title] {
    text-decoration: none;
    border-bottom: 0;
    cursor: inherit !important;
}

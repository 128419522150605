.c-home {

    &-top {
        margin-bottom: $gutter * 3;

        @include media-breakpoint-down(xl) {
            margin-bottom: $gutter-sm * 3
        }

        @media (max-width: 550px) {
            margin-bottom: 40px;
        }

        &__wrap {
            padding: $gutter * 2 0;
            padding-left: constant(safe-area-inset-right);
            padding-left: env(safe-area-inset-right);
            padding-right: constant(safe-area-inset-right);
            padding-right: env(safe-area-inset-right);
            margin: 0 $gutter * -1;

            @include media-breakpoint-down(xl) {
                padding: $gutter-sm * 2 0;
                padding-left: constant(safe-area-inset-right);
                padding-left: env(safe-area-inset-right);
                padding-right: constant(safe-area-inset-right);
                padding-right: env(safe-area-inset-right);
                margin: 0 $gutter-sm * -1;
            }

            @include media-breakpoint-down(md) {
                padding-top: 160px;
            }

            @media (max-width: 550px) {
                padding: 40px 0;
                padding-left: constant(safe-area-inset-right);
                padding-left: env(safe-area-inset-right);
                padding-right: constant(safe-area-inset-right);
                padding-right: env(safe-area-inset-right);
                margin: 0;
                padding-top: 110px;
            }
        }

        &__logo {
            margin-bottom: $gutter;
            max-width: 100%;
            height: auto;

            @include media-breakpoint-down(xl) {
                margin-bottom: 40px;
            }

            @media (max-width: 550px) {
                max-width: 120px;
                margin-bottom: 20px;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }

        &__text {
            display: block;
            max-width: 720px;
            font-size: 30px;
            line-height: 38px;

            @include media-breakpoint-down(lg) {
                font-size: 26px;
                line-height: 32px;
            }

            @media (max-width: 550px) {
                font-size: 18px;
                line-height: 24px;
            }

            a {
                font-style: italic;
            }
        }

        &__img {
            margin: 0 $gutter * -3;
            transition: $transition;
            position: relative;

            @include media-breakpoint-down(xl) {
                margin: 0 $gutter-sm * -3;
            }

            @media (max-width: 550px) {
                margin: 0 -30px;
            }

            &:after {
                content: '';
                display: block;
                width: 100%;
                padding-bottom: 56.25%;
            }

            img {
                width: 100%;
                height: auto;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &-props {
        margin-bottom: $gutter * 3;

        @include media-breakpoint-down(xl) {
            margin-bottom: $gutter-sm * 3;
        }

        &__row {
            display: flex;
            flex-wrap: wrap;
            margin: 0 $gutter * -2;

            @include media-breakpoint-down(xl) {
                margin: 0 $gutter-sm * -2;
            }

            @media (max-width: 550px) {
                margin: 0 -30px;
            }
        }

        &__col {
            padding: 0 $gutter * 2;
            width: 50%;

            @include media-breakpoint-down(xl) {
                padding: 0 $gutter-sm * 2;
            }

            @include media-breakpoint-only(lg) {
                width: 100%;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            @media (max-width: 550px) {
                padding: 0 30px;
            }

            &.t-full {
                width: 100%;
                margin-top: -40px;

                @include media-breakpoint-down(xl) {
                    margin-top: 0;
                }
            }
        }

        &__figure {
            margin-bottom: 220px;

            @include media-breakpoint-down(xl) {
                margin-bottom: 110px;
            }

            @media (max-width: 550px) {
                margin-bottom: 60px;
            }

            img {
                width: 100%;
                height: auto;
            }

            &-img {

                .t-left .t-big & {
                    margin-left: $gutter * -3;
                    margin-right: $gutter * -1;

                    @include media-breakpoint-down(xl) {
                        margin-left: $gutter-sm * -3;
                        margin-right: 0;
                    }

                    @media (max-width: 550px) {
                        margin-left: -30px;
                    }
                }

                .t-right .t-big  & {
                    margin-left: $gutter * -1;
                    margin-right: $gutter * -3;

                    @include media-breakpoint-down(xl) {
                        margin-left: 0;
                        margin-right: $gutter-sm * -3;
                    }

                    @media (max-width: 550px) {
                        margin-right: -30px;
                    }
                }

                .t-full & {

                    @include media-breakpoint-down(sm) {
                        margin-left: $gutter-sm * -3;
                    }

                    @media (max-width: 550px) {
                        margin-left: -30px;
                    }
                }

                &:not(.t-slider) {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        padding-bottom: 75%;
                    }

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                &.t-slider {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        padding-bottom: 62.222%;
                    }
                }
            }

            .t-full & {
                padding-left: $gutter * 3;
                margin-bottom: 0;

                @include media-breakpoint-down(xl) {
                    padding-left: $gutter-sm * 3;
                }

                @include media-breakpoint-down(sm) {
                    padding-left: 0;
                    max-width: 550px;
                    margin-left: auto;
                }
            }

            &.t-text {
                margin-bottom: 140px;
                font-weight: 700;

                @include media-breakpoint-down(xl) {
                    margin-bottom: 70px;
                }

                @include media-breakpoint-only(lg) {
                    max-width: 440px;
                }

                @include media-breakpoint-down(sm) {
                    max-width: 440px;
                }

                @media (max-width: 550px) {
                    margin-bottom: 40px;
                }
            }

            &.t-big {

                @include media-breakpoint-only(lg) {
                    max-width: 550px;
                }

                @include media-breakpoint-down(sm) {
                    max-width: 550px;
                }

                .t-right & {

                    @include media-breakpoint-only(lg) {
                        margin-left: auto;
                    }

                    @include media-breakpoint-down(sm) {
                        margin-left: auto;
                    }
                }
            }

            &.t-small {

                @include media-breakpoint-only(lg) {
                    max-width: 440px;
                }

                @include media-breakpoint-down(sm) {
                    max-width: 440px;
                }

                .t-left & {

                    @include media-breakpoint-only(lg) {
                        margin-left: auto;
                    }

                    @include media-breakpoint-down(sm) {
                        margin-left: auto;
                    }
                }
            }
        }

        &__figcaption {
            // overflow: hidden;

            &-title {
                position: relative;
                z-index: 1;
                margin-top: -42px;
                margin-left: $gutter * -1;
                display: block;

                @include media-breakpoint-down(xl) {
                    margin-left: $gutter-sm * -1;
                }

                @include media-breakpoint-only(lg) {
                    font-size: 60px;
                    line-height: 68px;
                    margin-top: -42px;
                }

                @include media-breakpoint-down(md) {
                    margin-top: -29px;
                }

                @include media-breakpoint-only(sm) {
                    font-size: 60px;
                    line-height: 68px;
                    margin-top: -42px;
                }

                @media (max-width: 550px) {
                    margin-left: -15px;
                    margin-bottom: 20px;
                }

                .t-left .t-small & {

                    @include media-breakpoint-only(lg) {
                        text-align: right;
                        margin-left: 0;
                        margin-right: $gutter-sm * -1;
                    }

                    @include media-breakpoint-down(sm) {
                        text-align: right;
                        margin-left: 0;
                        margin-right: $gutter-sm * -1;
                    }

                    @media (max-width: 550px) {
                        margin-right: -15px;
                    }
                }

                .t-right .t-big & {
                    text-align: right;
                    margin-left: 0;
                    margin-right: $gutter * -1;

                    @include media-breakpoint-down(xl) {
                        margin-right: $gutter-sm * -1;
                    }

                    @media (max-width: 550px) {
                        margin-right: -15px;
                    }
                }

                .t-full & {
                    margin-left: $gutter * -3;
                    margin-top: -122px;
                    max-width: 480px;
                    pointer-events: none;

                    @include media-breakpoint-down(xl) {
                        margin-left: $gutter-sm * -3;
                        margin-top: -99px;
                    }

                    @include media-breakpoint-only(lg) {
                        margin-top: 58px;
                    }

                    @include media-breakpoint-down(md) {
                        margin-top: -75px;
                    }

                    @include media-breakpoint-down(sm) {
                        margin-top: 58px;
                        margin-left: $gutter-sm * -1;
                    }

                    @media (max-width: 550px) {
                        margin-left: -15px;
                    }
                }
            }

            &-text {
                display: block;

                p {
                    margin: 0;

                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }
                }

                .t-full & {
                    padding-right: 10%;
                }
            }
        }

        .c-carousel {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &-slide {
                width: 100%;
            }

            &-control {
                position: absolute;
                right: -25px;
                top: 100%;
            }
        }
    }

    &-contacts {

        &__title {
            text-align: center;
        }

        &__list {
            margin-bottom: 40px;
            text-align: center;

            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
            }
        }

        &__item {

            &-text {
                display: block;
            }

            &:not(:last-child) {
                margin-bottom: 28px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 18px;
                }
            }
        }

        &__social {
            text-align: center;
            font-weight: 700;
            font-size: 15px;
            line-height: 15px;
            margin-bottom: $gutter * 3;

            @include media-breakpoint-down(xl) {
                margin-bottom: $gutter-sm * 3;
            }

            &-list {
                display: flex;
                list-style: none;
                padding: 0;
                margin: 0;
                justify-content: center;
            }

            &-link {
                padding: 20px;
                display: block;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }

        &__map {
            background: #eaeaea;
            height: 400px;
            margin: 0 $gutter * -3;
            position: relative;

            @include media-breakpoint-down(xl) {
                margin: 0 $gutter-sm * -3;
            }

            @include media-breakpoint-down(sm) {
                height: 250px;
            }
        }
    }
}
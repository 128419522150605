//# Grid
//
//*

body {
    display: flex;
    min-height: 100vh;
}

.c-main {
    overflow: hidden;
    flex-grow: 1;
    opacity: 0;
    transition: 1s $cubic-bezier;
    display: flex;

    > .o-box {
        display: flex;
        flex-direction: column;

        > * {
            width: 100%;
        }
    }

    .is-page-open & {
        opacity: 1;
    }

    .is-page-open.is-sidebar-animate & {
        opacity: .2;
    }

    .is-sidebar-animate & {
        pointer-events: none;
    }
}

.o-box {
    width: 100%;
    padding: 0 $gutter * 3;

    @include media-breakpoint-down(xl) {
        padding: 0 $gutter-sm * 3;
    }

    @media (max-width: 550px) {
        padding: 0 30px;
    }
}

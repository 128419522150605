//# Fonts
//
//*

@mixin font-face($family, $filepath, $weight: normal, $style: normal) {
	@font-face {
		font-family: $family;
		src: url('#{$filepath}.woff') format('woff'),
			 url('#{$filepath}.ttf') format('truetype');
		font-weight: $weight;
		font-style: $style;
	}
}

//** Font weight hint
// 100 – thin
// 200 – extra light
// 300 – light
// 400 – regular (normal)
// 500 – medium
// 600 – semibold
// 700 – bold
// 800 – extrabold
// 900 – black

// @include font-face('Lato', '../fonts/Lato/Lato-Hairline', 100);
// @include font-face('Lato', '../fonts/Lato/Lato-HairlineItalic', 100, italic);
// @include font-face('Lato', '../fonts/Lato/Lato-Thin', 200);
// @include font-face('Lato', '../fonts/Lato/Lato-ThinItalic', 200, italic);
@include font-face('Lato', '../fonts/Lato/Lato-Light', 300);
@include font-face('Lato', '../fonts/Lato/Lato-LightItalic', 300, italic);
@include font-face('Lato', '../fonts/Lato/Lato-Regular', 400);
// @include font-face('Lato', '../fonts/Lato/Lato-RegularItalic', 400, italic);
// @include font-face('Lato', '../fonts/Lato/Lato-Medium', 500);
// @include font-face('Lato', '../fonts/Lato/Lato-MediumItalic', 500, italic);
@include font-face('Lato', '../fonts/Lato/Lato-SemiBold', 600);
// @include font-face('Lato', '../fonts/Lato/Lato-SemiBoldItalic', 600, italic);
@include font-face('Lato', '../fonts/Lato/Lato-Bold', 700);
@include font-face('Lato', '../fonts/Lato/Lato-BoldItalic', 700, italic);
// @include font-face('Lato', '../fonts/Lato/Lato-Heavy', 800);
// @include font-face('Lato', '../fonts/Lato/Lato-HeavyItalic', 800, italic);
@include font-face('Lato', '../fonts/Lato/Lato-Black', 900);
// @include font-face('Lato', '../fonts/Lato/Lato-BlackItalic', 900, italic);
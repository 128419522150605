//# Buttons
//
//*

@keyframes linkHover {
    0% {
        transform: none;
    }
    100% {
        transform: translateX(-50%);
    }
}

.btn {
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: 5px;
    padding: 16px 30px;
    padding-top: 17px;
    position: relative;

    .text {
        display: block;
        margin-right: -5px;
    }

    .line {
        display: block;
        position: absolute;
        transition: $transition;
        pointer-events: none;

        &:nth-of-type(1) {
            left: -10px;
            top: -10px;
            width: 2px;
            height: 0;
        }

        &:nth-of-type(2) {
            left: -10px;
            top: -10px;
            height: 2px;
            width: 0;
        }

        &:nth-of-type(3) {
            right: -10px;
            bottom: -10px;
            width: 2px;
            height: 0;
        }

        &:nth-of-type(4) {
            right: -10px;
            bottom: -10px;
            height: 2px;
            width: 0;
        }
    }

    &:hover {

        .line {

            &:nth-of-type(1) {
                height: calc(100% + 20px);
            }

            &:nth-of-type(2) {
                width: calc(100% + 20px);
            }

            &:nth-of-type(3) {
                height: calc(100% + 20px);
            }

            &:nth-of-type(4) {
                width: calc(100% + 20px);
            }
        }
    }

    &-dark {

        .line {
            background-color: $dark;
        }
    }

    &-light {

        .line {
            background-color: $white;
        }
    }

    &-primary {

        .line {
            background-color: $primary;
        }
    }

    &-link {
        font-weight: 700;
        padding: 7px 0;
        border: none;
        position: relative;
        color: $body-color;
        overflow: hidden;

        &:hover,
        &:focus {
            text-decoration: none;

            &:after {
                opacity: 1;
                animation: linkHover 1s linear infinite;
            }
        }

        &:before,
        &:after {
            content: '';
            width: 100%;
            height: 2px;
            background: currentColor;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: $transition;
        }

        &:after {
            width: 233%;
            opacity: 0;
            background-image: linear-gradient(to left, $event, $coworking, $offices 20%, $event, $coworking, $offices, $event);
        }
    }
}